<script lang='ts'>
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import ButtonGroup from '@igefa-e-commerce/pattern-library/src/components/ButtonGroup/ButtonGroup.svelte'
import Heading from '@igefa-e-commerce/pattern-library/src/components/Heading/Heading.svelte'
import { fragments } from '../../../fragments.ts'
import { pages } from '../../../pages.ts'
import type {
  ArticleNameAndImage,
  ImageAttributes,
  OrderedArticleInfo,
} from '../../../types'
import DynamicInclude from '../../atoms/DynamicInclude/DynamicInclude.svelte'
import ImageGalleryCarousel from '../../atoms/ImageGalleryCarousel/ImageGalleryCarousel.svelte'

export let isUserLoggedIn = false
export let articles: ArticleNameAndImage[] = []

const articleImages: ImageAttributes[] = articles
  .slice(0, 10)
  .map(article => ({ src: article.image, alt: article.name }))
</script>

<div class='promotion-tile' data-testid='promotion-tile'>
  {#if !isUserLoggedIn || articles.length === 0}
    <DynamicInclude url={fragments.checkout.promotionCarousel(true)} />
  {:else}
    <div class='reorder'>
        <Heading variant="text">Ihre zuletzt bestellten Artikel</Heading>
      {#if articles.length <= 3}
        <div class="gallery-static">
          {#each articleImages as articleImage}
            <img src={articleImage.src} alt={articleImage.alt} />
          {/each}
        </div>
      {:else}
        <ImageGalleryCarousel images={articleImages}/>
      {/if}
      <ButtonGroup align="center">
        <Button variant="tertiary" on:click={() => window.location.href = pages.user.orderedArticles}>Zum Nachbestellen</Button>
      </ButtonGroup>
    </div>
  {/if}
</div>

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/border-radius';
  @use '@igefa-e-commerce/pattern-library/src/css/colors';
  @use '@igefa-e-commerce/pattern-library/src/css/spacers';

  .reorder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: spacers.$space-s;
    max-width: 100%;

    :global(.heading--text) {
      text-wrap: nowrap;
    }
  }

  .gallery-static {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: calc(2 * spacers.$space-s);
    padding: spacers.$space-xs 0;
  }

  .gallery-static img {
    max-height: 6rem;
  }
</style>

<script lang="ts">
import type { ImageAttributes } from '../../../types'

export let images: ImageAttributes[] = []
let numberOfImages = images.length
</script>

<div class="gallery-wrapper">
    <div class="gallery-slide" style='--numberOfImages:{numberOfImages}'>
      {#each images as image}
        <img src={image.src} alt={image.alt} />
      {/each}
    </div>
    <div class="gallery-slide" style='--numberOfImages:{numberOfImages}'>
      {#each images as image}
        <img src={image.src} alt={image.alt} />
      {/each}
    </div>
</div>

<style lang="scss">
  @use '@igefa-e-commerce/pattern-library/src/css/colors';
  @use '@igefa-e-commerce/pattern-library/src/css/spacers';
  @use '@igefa-e-commerce/pattern-library/src/css/fonts';

  .gallery-wrapper {
    padding: spacers.$space-xs 0;
    background: colors.$color_neutral_000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  .gallery-slide {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    animation: calc( var(--numberOfImages) * 5s) scroll infinite linear;
  }

  .gallery-slide img {
    max-height: 6rem;
    margin: 0 spacers.$space-s;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
</style>
